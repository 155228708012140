<template>
    <el-dialog
        center
        v-bind="$attrs"
        v-on="$listeners"
        width="40%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <el-form
            class="g-el-form"
            :model="ajaxParam"
            :rules="rules"
            ref="refElForm"
            label-width="160px"
        >
            <el-form-item
                :label="`${'所属公司'}:`"
                prop="companyId"
                v-if="$admin"
            >
                <GlobalCompanySelect
                    v-model="ajaxParam.companyId"
                    placeholder="请选择所属公司"
                    @change="
                        () => {
                            ajaxParam.carId = '';
                        }
                    "
                ></GlobalCompanySelect>
            </el-form-item>
            <el-form-item :label="`${'车牌号'}:`" prop="carId">
                <GlobalCarSelect
                    v-model="ajaxParam.carId"
                    :companyId="ajaxParam.companyId"
                    @clear="ajaxParam.carId = ''"
                />
            </el-form-item>
            <el-form-item :label="`${'零件名称'}:`" prop="parts">
                <GlobalDictSelect
                    v-model="ajaxParam.parts"
                    placeholder="请选择零件"
                    parentCode="PART_TYPE"
                ></GlobalDictSelect>
            </el-form-item>

            <el-form-item :label="`${'数量'}:`" prop="count">
                <el-input
                    v-model="ajaxParam.count"
                    placeholder="请输入数量"
                ></el-input>
            </el-form-item>
            <el-form-item :label="`${'更换日期'}:`" prop="replaceTime">
                <GlobalElDatePicker
                    v-model="ajaxParam.replaceTime"
                    placeholder="请选择更换日期"
                    @change="replaceTimeOnChange"
                ></GlobalElDatePicker>
            </el-form-item>
            <el-form-item
                :label="`${'下一次更换日期'}:`"
                prop="nextReplaceTime"
            >
                <GlobalElDatePicker
                    v-model="ajaxParam.nextReplaceTime"
                    placeholder="请选择下一次更换日期"
                    @change="nextReplaceTimeOnChange"
                ></GlobalElDatePicker>
            </el-form-item>
            <el-form-item :label="`${'备注'}:`" prop="remarks">
                <el-input
                    v-model="ajaxParam.remarks"
                    placeholder="请输入备注"
                ></el-input>
            </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer" style="text-align: center">
            <el-button type="primary" @click="onConfirm">确定</el-button>
            <el-button type="info" @click="onCancel">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { rules } from "./rules";
export default {
    computed: {
        rules() {
            return rules;
        },
    },
    title: "操作数据",
    name: "OperatData",
    props: {
        op: {
            type: Object,
            default: () => {
                return {
                    id: "0",
                    // show: false,
                    // row: null,
                    code: "init",
                };
            },
        },
    },

    data() {
        return {
            ajaxParam: {
                companyId: this.$admin ? "" : this.$companyId,
                carId: "",
                count: "",
                replaceTime: "",
                nextReplaceTime: "",
                remarks: "",
                parts: "",
            },
        };
    },
    created() {
        if (this.op.id != "0") this.lookfor();
    },
    methods: {
        onConfirm() {
            this.$refs.refElForm.validate((valid) => {
                if (valid) {
                    this.submitData();
                }
            });
        },
        replaceTimeOnChange() {
            if (
                new Date(this.ajaxParam.nextReplaceTime).valueOf() <
                new Date(this.ajaxParam.replaceTime).valueOf()
            ) {
                this.ajaxParam.replaceTime = "";
                this.$message({
                    message: "更换日期应该小于下一次更换日期",
                    type: "warning",
                    center: true,
                });
            }
        },
        nextReplaceTimeOnChange() {
            if (
                new Date(this.ajaxParam.nextReplaceTime).valueOf() <=
                new Date(this.ajaxParam.replaceTime).valueOf()
            ) {
                this.ajaxParam.nextReplaceTime = "";
                this.$message({
                    message: "下一次更换日期应该大于更换日期",
                    type: "warning",
                    center: true,
                });
            }
        },
        async lookfor() {
            // 查看详情
            let { code, data } = await this.$api.get(
                `/car/server/escort/web/carPartsReplaceRecord/${this.op.id}`
            );
            if (code == 200) {
                Object.keys(this.ajaxParam).forEach((key) => {
                    this.ajaxParam[key] = data[key];
                });
                this.ajaxParam.count = this.ajaxParam.count + "";
            }
        },
        async submitData() {
            // 提交(编辑)数据
            let { code } =
                this.op.id == "0"
                    ? await this.$api.post(
                          "/car/server/escort/web/carPartsReplaceRecord",
                          this.ajaxParam
                      )
                    : await this.$api.put(
                          `/car/server/escort/web/carPartsReplaceRecord/${this.op.id}`,
                          this.ajaxParam
                      );
            if (code == 200) {
                this.$message({
                    message: "操作成功",
                    type: "success",
                    center: true,
                });
                this.$emit("query");
                this.onCancel();
            }
        },
        onCancel() {
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss" scoped>
.g-el-form {
    padding: 0 50px;

    .el-select {
        width: 100%;
    }
    .el-date-editor {
        width: 100%;
    }
}
</style>
