<template>
    <el-dialog
        center
        v-bind="$attrs"
        v-on="$listeners"
        width="40%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <el-form
            class="g-el-form"
            size="mini"
            ref="refElForm"
            label-width="100px"
        >
            <el-form-item :label="`${'所属公司'}:`" prop="key1">
                {{ ajaxParam.key1 }}
            </el-form-item>
            <el-form-item :label="`${'标题'}:`" prop="key2">
                {{ ajaxParam.key1 }}
            </el-form-item>
            <el-form-item :label="`${'知识类别'}:`" prop="key2">
                {{ ajaxParam.key1 }}
            </el-form-item>
            <el-form-item :label="`${'封面'}:`" prop="key2">
                图片上传
                {{ ajaxParam.key1 }}
            </el-form-item>
            <el-form-item :label="`${'内容'}:`" prop="key2">
                富文本
                {{ ajaxParam.key1 }}
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
export default {
    title: "查看数据",
    name: "LookData",
    props: {
        op: {
            type: Object,
            default: () => {
                return {
                    id: "0",
                    // show: false,
                    // row: null,
                    code: "init",
                };
            },
        },
    },
    data() {
        return {
            ajaxParam: null,
        };
    },
    created() {
        if (this.op.id != "0") this.lookfor();
    },
    methods: {
        async lookfor() {
            // 查看详情
            let { code, data } = await this.$api.get(
                `/info/server/escort/web/journalism/${this.op.id}`
            );
            if (code == 200) {
                this.ajaxParam = data;
                // Object.keys(this.ajaxParam).forEach((key) => {
                //     this.ajaxParam[key] = data[key];
                // });
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
