import { charMax, requiredInput, requiredSelect } from "@/rules";
export const rules = {
    companyId: [requiredSelect()],

    carId: [requiredSelect()],
    count: [
        requiredInput(),
        charMax(10),

        {
            trigger: "change",

            validator: (rule, value, callback) => {
                if (!/^\d*$/.test(value + "")) {
                    callback(new Error("数量只能输入正整数"));
                } else {
                    callback();
                }
            },
        },
    ],
    replaceTime: [requiredSelect()],
    nextReplaceTime: [requiredSelect()],
    remarks: "",
    parts: [requiredSelect(), charMax(200)],

    key2: [
        requiredSelect(),
        {
            trigger: "change",

            validator: (rule, value, callback) => {
                if (!/^\d*$/.test(value + "")) {
                    callback(new Error("排序只能输入0或正整数"));
                } else if ((value + "").length > 5) {
                    callback(new Error("排序字符长度不超过5个字符"));
                } else {
                    callback();
                }
            },
        },
    ],
};
